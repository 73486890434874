import React, { useEffect } from 'react'
import NavBarComp from '../Components/NavBar'
import { Row, Dropdown, Button, Container, Col, Modal } from 'react-bootstrap'
import { useState } from 'react'
import serverFetch from '../Modules/serverFetch'

function Bestellungen() {

    const [NavBar, updateWarenkorb] = NavBarComp()
    const [response, setResponse] = useState()
    const [responseBestellung, setResponseBestellung] = useState()
    const [bestellungen, setBestellungen] = useState([])
    const [isVisable, setVisable] = useState(false)

    useEffect(() => {
        let user = localStorage.getItem("uid")
        serverFetch("Bestellungen", 'https://safe-reef-41356.herokuapp.com/getAllBestellungen', { uid: user }, setResponse)
    }, [])

    useEffect(() => {
        if (response) {
            let result = response.result
            console.log("hier")
            setBestellungen(JSON.parse(result))
        }
    }, [response])

    useEffect(() => {
        let user = localStorage.getItem("uid")
        serverFetch("Bestellungen", 'https://safe-reef-41356.herokuapp.com/getAllBestellungen', { uid: user }, setResponse)
    }, [responseBestellung])

    const erneutBestellen = (i) => {
        let user = localStorage.getItem("uid")
        serverFetch("Bestellung", "https://safe-reef-41356.herokuapp.com/checkout", { vorname: i.vorname, nachname: i.nachname, email: i.email, adresse: i.adresse, adressOpt: i.adressOpt, land: i.land, bl: i.bl, plz: i.plz, payment: i.payment, items: JSON.stringify(i.artikel), uid: user }, setResponseBestellung)

    }

    return (
        <div>
            <NavBar />
            <h2 class="text-center mt-4">Bestellübersicht</h2>
            <br /><br /><br />
            <Container fluid className="pl-3 pr-3">
                <Container fluid className="shadow-lg p-5 mb-4 bg-white rounded">
                    <div class="mb-3">
                        {bestellungen.length != 0 ?
                            <Container >
                                <Row fluid className="d-flex justify-content-center">
                                    {bestellungen.map(i => {
                                        const date = new Date(i.datum)
                                        console.log(date)
                                        let sum = 0
                                        return (
                                            <Col fluid md={3} className=" mr-4 rounded pt-3 mb-3" style={{ backgroundColor: "black" }}>
                                                <p style={{ color: 'white'}}>Am: {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()} um {date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}</p>
                                                <Col>
                                                    {i.artikel.map(t => {
                                                        sum += Number(t.price)
                                                        return (
                                                            <Row style={{ backgroundColor: '#00000090', borderRadius: 5, marginTop: 10 }}>
                                                                <Col>
                                                                    <p style={{ color: 'white', alignSelf: 'flex-start' }}>{t.title}</p>
                                                                </Col>
                                                                <Col>
                                                                    <p className="ml-1" style={{ color: 'white' }}>{t.price}€</p>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                </Col>
                                                <p style={{ color: 'white', textAlign: 'center', marginTop: 5 }}>Summe: {sum.toFixed(2)}€</p>
                                                <Col>
                                                    <Row>
                                                            <Container>
                                                        <Button onClick={() => erneutBestellen(i)} className="w-100 mb-3" style={{ borderRadius: 5}}>
                                                            <p>Erneut bestellen</p>
                                                        </Button>
                                                        </Container>
                                                    </Row>
                                                </Col>                                       
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Container>

                            :
                            <p>Keine Bestellung</p>
                        }
                    </div>
                </Container>
            </Container>
        </div>
    )
}

export default Bestellungen
