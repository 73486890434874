import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from "./Screens/Register"
import Home from "./Screens/Home"

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Confirm from './Screens/Confirm';
import ManParfuem from './Screens/ManParfuem';
import WomenParfuem from './Screens/WomenParfuem';
import ManBeauty from './Screens/ManBeauty';
import WomenBeauty from './Screens/WomenBeauty';
import Warenkorb from './Screens/Warenkorb';
import Checkout from './Screens/Checkout';
import Login from './Screens/Login';
import Bestellungen from './Screens/Bestellungen';
import ProtectedRoute from './Modules/ProtectedRoute'
import {WSProvider} from "./Context/websocket"

function App() {
  

  
  return (
      <Router>
        <WSProvider>
        <Switch>
          <ProtectedRoute exact path="/" component={Home}></ProtectedRoute>
          <Route path="/register" component={Register}></Route>
          <Route path="/confirm/:uid" component={Confirm}></Route>
          <ProtectedRoute path="/womanParfuem" component={WomenParfuem}></ProtectedRoute>
          <ProtectedRoute path="/womanBeauty" component={WomenBeauty}></ProtectedRoute>
          <ProtectedRoute path="/manParfuem" component={ManParfuem}></ProtectedRoute>
          <ProtectedRoute path="/manBeauty" component={ManBeauty}></ProtectedRoute>
          <ProtectedRoute path="/warenkorb" component={Warenkorb}></ProtectedRoute>
          <ProtectedRoute path="/checkout" component={Checkout}></ProtectedRoute>
          <Route path="/login" component={Login}></Route>
          <ProtectedRoute path="/bestellungen" component={Bestellungen}></ProtectedRoute>
        </Switch>
        </WSProvider>
      </Router>     
  );
}

export default App;
