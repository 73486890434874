var bcrypt = require('bcryptjs');

export default () => {
    function crypt (password){

        const load = () => {
            const salt = bcrypt.genSaltSync(10)
            const hash = bcrypt.hashSync(password, salt)
            return(hash)
        }
        return(load())
    }
    function encrypt (password, hash){
        return bcrypt.compareSync(password, hash)
    }
    return [crypt,encrypt]
}


