import React from 'react'

export default function serverFetch(printText, url, bodyParams, setContent) {
    console.log(printText)
    const loadContent = async () => {
        const load = async () => {
        const request = await fetch(
            url,
            {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyParams),
            }
        );
        setContent(await request.json())
        };
        load();
    };
    loadContent();
}
