import React,{useEffect} from 'react'
import { useState } from 'react'
import {Form, Button, Image, Row, Col,Container} from 'react-bootstrap'
import serverFetch from '../Modules/serverFetch'
import {useHistory} from "react-router-dom";

function Login() {

    const [email, setEmail] = useState("")
    const [passwort, setPasswort] = useState("")
    const [response, setResponse] = useState(null)
    const [err, setErr] = useState(false)
    const history = useHistory()

    const login = async () => {
        serverFetch("Login","https://safe-reef-41356.herokuapp.com/login",{email: email, passwort: passwort},setResponse)
    }

    useEffect(() => {
        if(response != null){
            if(response.status == 400){
                setErr(true)
            }
            else if(response.status == 200){
                setErr(false)
                console.log(response)
                localStorage.setItem("uid",response.uid)
                localStorage.setItem("firstVisit", "true")
                history.push("/")
            }
        }  
    }, [response])

    return (
        <>
         <Container className="vertical-center" md="auto">
         <Image className="" style={{height:400}} src="https://firebasestorage.googleapis.com/v0/b/order-application-backend.appspot.com/o/website%2FBildschirmfoto%202021-01-08%20um%2000.07.07.png?alt=media&token=fb6f0b3d-3948-4807-96f2-c38cfe3c8d82"></Image>
         </Container>
         
        <Container className="jumbotron vertical-center" md="auto">
          <Row>
            <Col>
        <Form className="jumbotron" md="auto" style={{marginTop: "1%"}}>   
        <Form.Group controlId="formBasicEmail">
          <Form.Label>E-Mail Adresse</Form.Label>
          <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value) }  placeholder="E-Mail Adresse eingeben" />
          <Form.Text className="text-muted">
            Wir geben keine Informationen an dritte weiter
          </Form.Text>
        </Form.Group>
      
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Passwort</Form.Label>
          <Form.Control value={passwort} onChange={(event) => setPasswort(event.target.value) } type="password" placeholder="Passwort eingeben" />
        </Form.Group>
        {err?
            <p style={{color: '#FF5D3B'}}>E-Mail und Passwort stimmen nicht überein</p>
        :
            null
        }
        <Button onClick={() => login()} variant="primary">
          Login
        </Button>
        <Button className="ml-1" onClick={() => history.push("/register")} variant="primary">
          Registrieren
        </Button>
      </Form>
      </Col>
          </Row>
      </Container>
      </>
    )
}

export default Login
