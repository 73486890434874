import React, { useEffect } from 'react'
import { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import NavBarComp from '../Components/NavBar'
import ProductComp from '../Components/Product'
import {Search} from 'react-bootstrap-icons'
import serverFetch from '../Modules/serverFetch'



function ManBeauty() {

    const [content, setContent] = useState(null)
    const Product = ProductComp()
    const [NavBar, updateWarenkorb] = NavBarComp()
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        updateWarenkorb()
        const load = async () => {
            const request = await fetch(
                "https://safe-reef-41356.herokuapp.com/manBeauty",
                {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    }
                }
            );
            setContent(await request.json())
            };
            load();
    },[])
    
    useEffect(() => {
        console.log(content)
    },[content])


    if(content != null){
        console.log("hallo")
        return (
            <>
           <NavBar/>
           <Container>
               <Row>
                   <Form>
                        <Container>
                            <Row>
                                <Col style={{backgroundColor: 'lightgray'}} className="d-flex flex-row align-items-center mt-2">
                                <Search size={20}/>
                                <Form.Control style={{backgroundColor: 'lightgray'}} value={searchTerm} onChange={(event) => setSearchTerm(event.target.value) } className="" placeholder="Suche"/>
                                </Col>   
                            </Row>  
                            
                        </Container>
                   </Form>
               </Row>
               <Row>
                   {content.map(i => {

                       if(searchTerm == ""){
                            return  <Col>
                                        <Product updateWarenkorb={updateWarenkorb} url={i.imageUrl} title={i.title} price={i.price}/>
                                    </Col>
                       }
                       else{
                            if(i.title.toLowerCase().match(searchTerm.toLowerCase())){
                                return  <Col>
                                        <Product updateWarenkorb={updateWarenkorb} url={i.imageUrl} title={i.title} price={i.price}/>
                                    </Col>
                            }
                            else{
                                return null
                            }
                       }
                       
                      
                   })}
               </Row>
           </Container>
           </>
        )
    }
    else{
        return (
            <>
           <NavBar/>
           <Container>
               <Row>
               </Row>
           </Container>
           </>
        )
    }
    
}



export default ManBeauty

