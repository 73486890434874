import React,{useState, useEffect} from 'react'
import {Row, Col, Form, Container, Button, Navbar, Nav, NavDropdown, FormControl, Modal, Dropdown } from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import {Bag, Person, List, PersonBadge} from 'react-bootstrap-icons'
import {Badge} from '@material-ui/core'
import serverFetch from '../Modules/serverFetch'
import openSocket from 'socket.io-client'
import {useWS} from "../Context/websocket"


export default () => {

    const [warenkorbamount, setWarenkorbamount] = useState(0)
    const updateWarenkorb = () => {
        let test = JSON.parse(localStorage.getItem("warenkorb"))
        if(test){
            if(test.length != 0){
                setWarenkorbamount(test.length)
            }   
        }
   }

    function NavBar() {
        const [editIsVisable, setIsVisable] = useState(false)
        const [result, setResult] = useState([])
        const history = useHistory()
        const [response, setResponse] = useState()
        const [responseOnline, setResponseOnline] = useState()
        const [isLoading, setLoading] = useState(true)
        const value = useWS()
    //     const socket = openSocket("https://safe-reef-41356.herokuapp.com")

    //     useEffect(() => {
    //         socket.on('success', data => {
    //             console.log("new connection")
    //             setLoading(false)
    //             setResponseOnline(data.status)
    //             localStorage.setItem("usersOnline",data.status)
    //         });
    //         return() =>{
    //             socket.close()
    //         }
    //    },[])

        useEffect(() =>{
            console.log(responseOnline)
        },[responseOnline])
        

         useEffect(() => {   
            if(response){
                console.log(response)
            }
        }, [response])

        useEffect(() => {
            if(isLoading){
                let test = localStorage.getItem("usersOnline")
                console.log("item"+test)
                setResponseOnline(test)
            }
        },[])
    
        const showWarenkorb = () => {
            let test = localStorage.getItem("warenkorb")
            setResult(JSON.parse(test))
            setIsVisable(true)
        }

        const signout = () => {
            
            //localStorage.removeItem("warenkorb")
            localStorage.removeItem("usersOnline")
            let user = localStorage.getItem("user")
            let uid = localStorage.getItem("uid")
            console.log(user)
            localStorage.removeItem("user")
            localStorage.removeItem("uid")
            localStorage.removeItem("firstVisit")
            serverFetch("Login","https://safe-reef-41356.herokuapp.com/setUserOffline",{user: user, uid: uid},setResponse)
            history.push("/")
        }
    
    
        return (
            <>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to="/">Parfuem Elements</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <NavDropdown title="Kategorien" id="basic-nav-dropdown">
                        <NavDropdown.Header>Männer</NavDropdown.Header>
                        <NavDropdown.Item as={Link} to="/manParfuem">Parfüm</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/manBeauty">Pflege</NavDropdown.Item>
                        <NavDropdown.Header>Frauen</NavDropdown.Header>
                        <NavDropdown.Item as={Link} to="/womanParfuem">Parfüm Frau</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/womanBeauty">Pflege Frau</NavDropdown.Item>
                    </NavDropdown>
                    </Nav>
                    <Badge color="secondary" className="mr-4" badgeContent={value.currentUsers}>
                        <PersonBadge color="lightgrey" size={30} style={{}}/>
                    </Badge>
                    <Button onClick={() => showWarenkorb()} className="align-items-center mr-2" style={{backgroundColor: "white"}}>
                        <Badge color="secondary" badgeContent={warenkorbamount}>
                            <Bag color="lightgrey" size={30} style={{}}/>
                        </Badge>
                    </Button>
                    <Button onClick={() => history.push("/bestellungen")} className="align-items-center mt-3" style={{backgroundColor: "white"}}>
                        <List size={25} color="lightgrey"/>
                        <p style={{color: 'lightgrey'}}>Meine Bestellungen</p>
                    </Button>
                    <Button onClick={() => signout()} className="align-items-center mt-3" style={{backgroundColor: "white"}}>
                        <Person size={25} color="lightgrey"/>
                        <p style={{color: 'lightgrey'}}>Logout</p>
                    </Button>
                </Navbar.Collapse>
            </Navbar>
            <Modal
            show={editIsVisable}
            backdrop="static"
            keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Warenkorb</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {result?
                        result.map(elem => (
                            <Container>
                                <Row>
                                    <Col><p>{elem.title}</p></Col>
                                    <Col><p>{elem.price}</p></Col>
                                </Row>
                            </Container>
                        ))
                    :
                    null
                    }
                    <Row>
                        <Col><Button onClick={() => setIsVisable(false)}>Schließen</Button></Col>
                        <Col><Button onClick={() => {
                            setIsVisable(false) 
                            history.push("/warenkorb")
                            }}>zum Warenkorb</Button></Col>
                    </Row>
                </Modal.Body>
            </Modal>
          </>
        )
    }
    return [NavBar, updateWarenkorb]
}
