import React, {useState, useEffect} from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'


export default () => {

    const [warenkorb, setWarenkorb] = useState({warenkorb: []})
    function Product({updateWarenkorb,url,title,price}) {
        
        const addToCart = (title,price) => {
            let found = false
            let items =  JSON.parse(localStorage.getItem("warenkorb"))
            if(items){
                items.map(elem => {
                    if(elem.title === title){
                        found = true
                    }
                })
            }
            if(found !== true){
                let test = items
                if(items){
                    if(test.length !== 0){
                        let exits = items.filter((el) => {
                            return el.title === title
                        })
                        if(exits.length == 0){
                            setWarenkorb(state => ({warenkorb:[...items,{title: title, price: price,url: url}]}))
                        }
                    }
                    else{
                        setWarenkorb(state => ({warenkorb:[{title: title, price: price, url: url}]}))
                    }
                }
                else{
                    setWarenkorb(state => ({warenkorb:[{title: title, price: price, url: url}]}))
                }
            }   
            
        } 
        
        useEffect(() => {
            if(warenkorb.warenkorb.length != 0){
                console.log("change")
                localStorage.setItem("warenkorb",JSON.stringify(warenkorb.warenkorb))
                updateWarenkorb()
            }
        },[warenkorb])
    
        return (
           <Container className="shadow-lg p-3 mb-5 bg-white rounded" style={{borderWidth: 1, width:190, alignItems: 'center', justifyContent: 'center', marginTop: 20}} >
               <Row>
                   <Col >
                        <Image style={{height: 150, marginTop: 10, marginBottom: 10}} src={url}/>
                   </Col>
               </Row>
               <Row>
                   <Col>
                       <p style={{textAlign:'center'}}>{title} <br/> <big style={{fontWeight: 'bold'}}>{price}€</big> </p> 
                   </Col>
                   <Col>
                        <Button onClick={() => addToCart(title, price)}>
                            In den Warenkorb
                        </Button>
                   </Col>
               </Row>
           </Container>
        )
    }

    return Product
}

