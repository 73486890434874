import React,{useState, useEffect, useRef} from 'react'
import {Row, Col, Form, Container, Button, Alert} from 'react-bootstrap'
import mongoose from 'mongoose';
import serverFetch from '../Modules/serverFetch'
import {useHistory} from "react-router-dom";
import cryptPass from '../Modules/crypt'

function Confirm({match}) {

    const [einmalpasswort, setEinmalpasswort] = useState("")
    const [passwort, setPasswort] = useState("")
    const [bestaetigung, setbestaeting] = useState("")
    const [buttonState, setButtonState] = useState(true)
    const [response, setResponse] = useState({sucess: 400})
    const [isErrVisable, setErrVisable] = useState(true)
    const [crypt, encrypt] = cryptPass()
    const history = useHistory()



    useEffect(() => {
        if(response.sucess == 200){
            localStorage.setItem("uid",response.uid)
            localStorage.setItem("firstVisit", "true")
            history.push(`/`)
        }
        return () => {
        }
    }, [response])

    const handleLogin = () => {
        let hash = crypt(passwort)
        serverFetch("Confirm","https://safe-reef-41356.herokuapp.com/confirm",{einmalpasswort: einmalpasswort, passwort: hash, uid: match.params.uid},setResponse)
    }

    useEffect(() => {

        if(einmalpasswort != ""){
            if(bestaetigung != "" && bestaetigung == passwort && bestaetigung.length >= 8){
                setErrVisable(false)
                setButtonState(false)
            }
            else{
                setErrVisable(true)
                setButtonState(true)
            }
        }

        
    },[einmalpasswort,passwort,bestaetigung])

    return (
       
        <Container className="jumbotron vertical-center" md="auto" style={{marginTop: "20%"}}>
          <Row>
            <Col>
              <Form>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Einmalpasswort</Form.Label>
                    <Form.Control value={einmalpasswort} onChange={(event) => setEinmalpasswort(event.target.value) } placeholder="Password aus der E-Mail" />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Neues Passwort</Form.Label>
                    <Form.Control value={passwort} onChange={(event) => setPasswort(event.target.value) } type="password" placeholder="Password eingeben" />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Bestätigen</Form.Label>
                    <Form.Control value={bestaetigung} onChange={(event) => setbestaeting(event.target.value) } type="password" placeholder="Passwort erneut eingeben" />
                </Form.Group>
                <Button disabled={buttonState} onClick={() => handleLogin()} variant="primary">
                    Submit
                </Button>
                {einmalpasswort != ""?
                     <div>
                         {isErrVisable?
                            <>
                                <p>
                                Passwörter stimmen nicht überein oder wurden nicht ausgefüllt                    
                                </p>
                                <p>
                                    beachten Sie das Ihr Passwort mindestens 8 Zeichen enthält
                                </p>
                            </>
                         :
                         null
                         }
                       
                     </div>
                :
                    null
                }
              </Form>
            </Col>
          </Row>
      </Container>
    )
}

export default Confirm
