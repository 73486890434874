import React,{useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import NavBar from '../Components/NavBar'
import {Image, Row, Col, Button, Modal, Container} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import { Component } from 'react'
import serverFetch from '../Modules/serverFetch'

function Checkout () {

   const [vorname, setVorname] = useState("")
   const [nachname, setNachname] = useState("")
   const [email, setEmail] = useState("")
   const [adress, setAdresse] = useState("")
   const [adressOpt, setAdressOpt] = useState("")
   const [land, setLand] = useState("")
   const [bl, setBL] = useState("")
   const [code, setCode] = useState("")
   const [payment, setPayment] = useState(null)
   const [showModal, setShowModal] = useState(false)
   const [errorfield,setErrorField] = useState()
   const [response, setResponse] = useState()
   const history = useHistory()


   useEffect(() => {
       if(response){
            if(response.status === 200){
                history.push("/")
                localStorage.removeItem("warenkorb")
            }
            else{
                console.log("fehler")
            }
       }
   }, [response])

   const handleResult = (item) => {
        setPayment(item.id)
   }

   const executeBestellung = () => {
        let user = localStorage.getItem("uid")
        console.log(user)
        let items = localStorage.getItem("warenkorb")
        serverFetch("Bestellung","https://safe-reef-41356.herokuapp.com/checkout",{vorname: vorname, nachname: nachname, email: email, adress: adress, adressOpt: adressOpt, land: land, bl: bl, plz: code, payment: payment, items: items, uid: user},setResponse)
   }

   const checkFields = () => {
        if(vorname === ""){
            setErrorField("Vorname")
            setShowModal(true)
        }
        else if(nachname === ""){
            setErrorField("Nachname")
            setShowModal(true)
        }
        else if(email === "" && email !== "@"){
            setErrorField("Email")
            setShowModal(true)
        }
        else if(adress === ""){
            setErrorField("Adresse")
            setShowModal(true)
        }
        else if(land === ""){
            setErrorField("Land")
            setShowModal(true)
        }
        else if(bl === ""){
            setErrorField("Bundesland")
            setShowModal(true)
        }
        else if(code === ""){
            setErrorField("PLZ")
            setShowModal(true)
        }
        else{
            executeBestellung()
        }   
   }

   return (

    <div>
             <NavBar/>

            <section>
            <br /><br />
            <h2 class= "text-center">Checkout</h2>
            <br /><br /><br />
            <div class="row justify-content-center">
                <div class="col-lg-8">
                <Row className="shadow-lg p-2 mb-4 ml-3 bg-white rounded justify-content-center">
                    <div class="mb-3">
            <Form className="mt-2">
                <Row>
                    <Col>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control onChange={(event) => setVorname(event.target.value)} value={vorname} placeholder="First Name" />
                            </Col>
                    <Col>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control onChange={(event) => setNachname(event.target.value)} value={nachname} placeholder="Last Name" />
                    </Col>
                </Row>
            </Form><br />
            <Form>
                <Form.Group controlId="formGropEmail">
                <Form.Label>E-mail</Form.Label>
                <Form.Control type="email" onChange={(event) => setEmail(event.target.value)} value={email} type="email" placeholder="E-mail" />
            </Form.Group>
            </Form>
            <Form.Group controlId="formGroupAdress">
                <Form.Label>Adress</Form.Label>
                <Form.Control onChange={(event) => setAdresse(event.target.value)} value={adress} type="text" placeholder="1234 Main Str" />
            </Form.Group>
                <Form.Group controlId="formGropAdress">
                <Form.Label>Adress(optional)</Form.Label>
                <Form.Control onChange={(event) => setAdressOpt(event.target.value)} value={adressOpt} placeholder="Apartment or Suite" />
            </Form.Group>
            <Form.Row>
            <Form.Group as={Col} controlId="formGridCountry">
                <Form.Label>Country</Form.Label>
                <Form.Control onChange={(event) => setLand(event.target.value)} value={land} placeholder="County">
                
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Control onChange={(event) => setBL(event.target.value)} value={bl} placeholder="Bundesland">
               
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control onChange={(event) => setCode(event.target.value)} value={code} placeholder="12345"/>
            </Form.Group>
            </Form.Row>
            <br />
            <Form.Group as={Row} controlId="formShippingAdressCheck">
                <Col sm={{ span: 10, offset: 2 }}>
                    <Form.Check label="Shipping address is the same as my billing address" />
                </Col>
            </Form.Group> 
            <Form.Group as={Row} controlId="formRememberMeCheck">
                <Col sm={{ span: 10, offset: 2 }}>
                    <Form.Check label="Save this information for the next time" />
                </Col>
            </Form.Group>
            <br /><hr /><br />
                 <fieldset>
                    <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={2}>
                            Payment
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Check
                                type="radio"
                                label="Debit Card"
                                name="formHorizontalRadios"
                                id="Debit"
                                onChange={(event) => handleResult(event.target)}
                            />
                            <Form.Check
                                type="radio"
                                label="Credit Card"
                                name="formHorizontalRadios"
                                id="Credit"
                                onChange={(event) => handleResult(event.target)}
                            />
                            <Form.Check
                                type="radio"
                                label="Pay-Pal"
                                name="formHorizontalRadios"
                                id="PayPal"
                                onChange={(event) => handleResult(event.target)}
                            />
                        </Col>
                    </Form.Group>
                </fieldset>
                {payment != null?
                    <Button onClick={() => checkFields()} className="w-100"> Bezahlen</Button>
                :
                    null
                }
                
                {/*<Form>
                    <Form.Group controlId="formBasicNameAufKarte">
                        <Form.Label>Name on Card</Form.Label>
                        <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                            Ganzer Name, wie er auf der Karte steht
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicKreditKartenNummer">
                        <Form.Label>Kreditkartennummer</Form.Label>
                        <Form.Control type="text" placeholder="" />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Gültig bis</Form.Label>
                        <Form.Control type="" placeholder="" />
                    </Form.Group>

                    <Form.Group controlId="formBasicKartenPrüfNummer">
                        <Form.Label>CVV</Form.Label>
                        <Form.Control type="text" placeholder="" />
                    </Form.Group>
                </Form>*/}
                        </div>
                        </Row>
                        </div>
            </div>
        </section>
        <Modal
            show={showModal}
            backdrop="static"
            keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Fehler</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col><p>Bitte füllen Sie das folgende Feld aus:</p></Col>
                            <Col><p style={{fontWeight: "800"}}>{errorfield}</p></Col>
                        </Row>
                    </Container>
                    <Row>
                        <Col><Button onClick={() => setShowModal(false)}>OK</Button></Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
        

        

                  
   )
}

export default Checkout