import React, {useEffect} from 'react'
import { useState } from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

function ProtectedRoute({component: Component, ...rest}) {
    
    return (
        <Route {...rest}
        render={(props)=>{
           return localStorage.getItem("uid")? //If(user eingeloggt)
            <Component {...props}/> 
            :
            <Redirect to="/login"/>
        }}
    />
    )
}

export default ProtectedRoute
