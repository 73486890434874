
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import NavBarComp from '../Components/NavBar'
import {Carousel, Container, Image, Row, Col, Modal, Button} from 'react-bootstrap'
import serverFetch from '../Modules/serverFetch'
import ProductComp from '../Components/Product'



function Home() {

    const [user, setUser] = useState("")
    const [content, setContent] = useState(null)
    const [response, setResponse] = useState()
    const [date, setDate] = useState(new Date())
    const Product = ProductComp()
    const [NavBar, updateWarenkorb] = NavBarComp()
    const [isVisable, setVisable] = useState()
    console.log(localStorage.getItem("firstVisit"))

    useEffect(() => {
        updateWarenkorb()
        let uid = localStorage.getItem("uid")
        serverFetch("fetch","https://safe-reef-41356.herokuapp.com/getUserTime",{uid: uid},setResponse)
        const loadCat = async () => {
            const requestCat = await fetch(
                "https://safe-reef-41356.herokuapp.com/manParfuem",
                {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    }
                }
            );
            setContent(await requestCat.json())
            };
            loadCat();
        console.log(user)
        if(!localStorage.getItem("user")){
            console.log("bin hier")
            const load = async () => {
                const request = await fetch(
                    "https://safe-reef-41356.herokuapp.com/setUserOnline",
                    {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        }
                    }
                );
                console.log("penis")
                let userid = await request.json()
                setUser(userid)
                localStorage.setItem("user",userid)
                };
            load();
        }
    },[])

    useEffect(()=>{
        if(localStorage.getItem("firstVisit") == "true"){
            if(response){
                if(response.lastseen){
                    console.log(response.lastseen)
                    console.log(new Date(Number(response.lastseen)))
                    setDate(new Date(Number(response.lastseen)))
                    setVisable("true")
                }
                else{
                    console.log("empty")
                    setVisable("false")
                }
            }
        }
        console.log(response)
        
    },[response])

    const handleFirstVisit = () => {
        setVisable("false")
        localStorage.setItem("firstVisit","false")
    }

    return (
        <>
        <NavBar/>
        <Carousel onChange={(item) => console.log(item)}>
            <Carousel.Item>
                <Image className="d-block w-100" src="https://c.perfumesclub.com/nuevapresentacion/marcas/dg/dg-head-banner.jpg"/>
            </Carousel.Item>
            <Carousel.Item>
                <Image className="d-block w-100" src="https://c.perfumesclub.com/nw/nomadeedp/footer.jpg"/>
            </Carousel.Item>
            <Carousel.Item>
                <Image className="d-block w-100" src="https://www.007fragrances.com/wp-content/uploads/2019/06/perfect_gift_her_de.jpg"/>
            </Carousel.Item>
        </Carousel>
        <Container className="mt-3">
            <h3>Neu im Sortiment</h3>
            {content != null?
            <Row>
                
                {content.map(i => {
                    return    <Col>
                                <Product updateWarenkorb={updateWarenkorb} url={i.imageUrl} title={i.title} price={i.price}/>
                            </Col>
                   })}
                
            </Row>
            :
            null
            }
        </Container>
            <Modal
                show={isVisable == "true"? true: false }
                backdrop="static"
                keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Wilkommen zurück</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col><p>Sie waren zuletzt online am {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()} um {date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}</p></Col>
                            </Row>
                        </Container>
                        <Row>
                            <Col><Button onClick={() => handleFirstVisit()}>OK</Button></Col>
                        </Row>
                    </Modal.Body>
                </Modal>
        
        </>
    )
}

export default Home
