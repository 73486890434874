import React, {useEffect, useState} from 'react'
import NavBarComp from '../Components/NavBar'
import {Image, Row, Col, Button, Form, Container} from 'react-bootstrap'
import {Trash} from 'react-bootstrap-icons'
import {Link, useHistory} from 'react-router-dom'
import moment from 'moment'

function Warenkorb() {

    const [result, setResult] = useState([])
    const lieferdatum = moment(new Date()).add(3, 'days')
    const lieferdatum2 = moment(new Date()).add(6, 'days')
    const lieferdatumexpress = moment(new Date()).add(1, 'days')
    const [summe, setSumme] = useState(0)
    const [finalSumme, setFinalSumme] = useState(0)
    const [NavBar, updateWarenkorb] = NavBarComp()
    const [versand, setVersand] = useState("Standar")
    const history = useHistory()

    useEffect(() => {
        updateWarenkorb()
        let sum = 0
        let res = localStorage.getItem("warenkorb")
        if(res){
            setResult(JSON.parse(res))
            let Zwischensumme = JSON.parse(res)
            Zwischensumme.forEach(element => {
                sum += Number(element.price)
            });
            console.log("neeeoder")
            setSumme(sum)
            setFinalSumme(sum)
        }
    }, [])

    useEffect(() => {
        let sum = 0
        if(versand==="Express"){
            sum = summe + 4.99
            setFinalSumme(sum)
        }
        else if(versand ==="Standard"){
            setFinalSumme(summe)
        }
    }, [versand])

    useEffect(() => {
        console.log("result change")
        console.log(result)
        updateWarenkorb()
    },[result])

    const deleteProduct = (title) => {
        
        const array = [... result]
        let obj = array.findIndex(element => element.title === title)
        console.log(obj)
        array.splice(obj, 1)
        console.log(array)
        setResult(array)
        let sum = 0
        array.forEach(element => {
            sum += Number(element.price)
        })
        setSumme(sum)
        setFinalSumme(sum)
        localStorage.setItem("warenkorb",JSON.stringify(array))
        updateWarenkorb()
    }

    const checkout = () => {
        history.push("/checkout")
    }

    return (
        <div>
             <NavBar/>

            <section>

            {result.length !== 0?
            <div class="row">
                <div class="col-lg-8">
                    <div class="mb-3">
                        <div class="pt-4">
                            <h5 class="mb-4 ml-3">Warenkorb (<span>{result.length}</span> Artikel)</h5>
                            {result.length !== 0?
                                result.map(elem => {
                                    return(
                                        <Row className="shadow-lg p-3 mb-4 ml-3 bg-white rounded">
                                            <Col>
                                                <Image src={elem.url} style={{height: 150,}}/>
                                            </Col>
                                            <Col className="float-left">
                                                <p style={{fontSize: 20}}>{elem.title}</p>
                                                <p style={{fontSize: 20}}>{elem.price}€</p>
                                            </Col>
                                            <Button onClick={() => deleteProduct(elem.title)}>
                                                <Trash size={20}/>
                                            </Button>
                                        </Row>
                                    )
                                })
                            :
                            null
                            }
                        </div>
                    </div>

                    <div class="mb-3 ml-3">
                        <h5 class="mb-1">Voraussichtliches Lieferdatum</h5>
                        {versand === "Express"?
                            <p class="mb-0">{new Date(lieferdatumexpress).getDate()}.{new Date(lieferdatumexpress).getMonth()+1}.{new Date(lieferdatumexpress).getFullYear()}</p>
                        :
                            <p class="mb-0">{new Date(lieferdatum).getDate()}.{new Date(lieferdatum).getMonth()+1}.{new Date(lieferdatum).getFullYear()} - {new Date(lieferdatum2).getDate()}.{new Date(lieferdatum2).getMonth()+1}.{new Date(lieferdatum2).getFullYear()}</p>
                        }
                    </div>
                </div>

                <div class="col-lg-4">


                <div class="mb-3 mr-3">
                    <div class="pt-4">

                        <h5 class="mb-3">Rechungsbetrag</h5>

                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                            Zwischensumme
                            <span>{summe.toFixed(2)}€</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                            Lieferung
                            {versand === "Express"?
                                <span>4.99€</span>
                            :
                                <span>Gratis</span>
                            }
                            
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                            <div>
                                <strong>Summe</strong>
                                <strong>
                                <p class="mb-0">(enthält MwSt.)</p>
                                </strong>
                            </div>
                            <span><strong>{finalSumme.toFixed(2)}€</strong></span>
                            </li>
                        </ul>
                        <Container className="justify-content-center">
                        <h6>Versandart wählen:</h6>
                            <Form>
                                <Form.Row className="align-items-center">
                                    <Col xs="auto" className="my-1">
                                    <Form.Label className="mr-sm-2" htmlFor="inlineFormCustomSelect" srOnly>
                                        Preference
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="mr-sm-2"
                                        id="inlineFormCustomSelect"
                                        custom
                                        onChange={(event) => setVersand(event.target.value)}
                                    >
                                        <option value="Standard">Standard</option>
                                        <option value="Express">Express(Nächster Tag)</option>
                                    </Form.Control>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </Container>
                        <br /><hr /><br />
                        <Button onClick={() => checkout()} class="btn btn-primary btn-block">Checkout</Button>
                        </div>
                    </div>
                </div>
            </div>
            :
                <>
                <Container className="mt-4" style={{textAlign: 'center'}}>
                    <h5>Ihr Warenkorb ist leer!</h5>
                    <h6>legen Sie neue Produkte in den Warenkorb</h6>
                </Container>
                </>
            }
            </section>
        </div>
    )
}

export default Warenkorb
