import React, {useContext, useState, useEffect} from "react"
import openSocket from 'socket.io-client'


const WSContext = React.createContext()

export function useWS() {
    return useContext(WSContext)
}

export function WSProvider({children}){

    const socket = openSocket("https://safe-reef-41356.herokuapp.com")
    const [currentUsers, setCurrentUsers] = useState()

    

        useEffect(()=>{
            socket.on('success', data => {
                console.log("new connection")
                setCurrentUsers(data.status)
            });

            return(()=>{
                socket.disconnect()
            })

        },[])

       
 


    const value = {
        currentUsers
    }

    return (
        <WSContext.Provider value={value}>
            {children}
        </WSContext.Provider>
    )


}