import React,{useState, useEffect, useRef} from 'react'
import {Row, Col, Form, Container, Button, Alert, Image} from 'react-bootstrap'
import mongoose from 'mongoose';
import serverFetch from '../Modules/serverFetch'
import {useHistory} from "react-router-dom";

function Register() {

    const [passwort, setPasswort] = useState("")
    const [email, setEmail] = useState("")
    const [response, setResponse] = useState({sucess: 400})
    const [alert, setAlert] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if(response.status == 11000){
            setAlert(true)
        }
        if(response.status == 200){
            history.push(`/Confirm/${response.uid}`)
        }
        return () => {
        }
    }, [response])

    const handleLogin = () => {
         serverFetch("Login","https://safe-reef-41356.herokuapp.com/register",{email: email},setResponse)
      }

    return (
        <>
        <Alert show={alert} variant="danger">
        <Alert.Heading>Email existiert bereits</Alert.Heading>
        <div className="d-flex justify-content-end">
          <Button onClick={() => setAlert(false)} variant="danger">
           OK
          </Button>
        </div>
      </Alert>
        <Container className="vertical-center" md="auto">
         <Image className="" style={{height:400}} src="https://firebasestorage.googleapis.com/v0/b/order-application-backend.appspot.com/o/website%2FBildschirmfoto%202021-01-08%20um%2000.07.07.png?alt=media&token=fb6f0b3d-3948-4807-96f2-c38cfe3c8d82"></Image>
         </Container>
        <Container className="jumbotron vertical-center" md="auto">
          <Row>
            <Col>
              <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>E-Mail Adresse</Form.Label>
                <Form.Control value={email} onChange={(event) => setEmail(event.target.value)} type="email" placeholder="E-Mail Adresse" />
                <Form.Text className="text-muted">
                  Wir werden personenbezogene Daten nicht an Dritte weiterleiten
                </Form.Text>
              </Form.Group>
              <Button onClick={() => handleLogin()} variant="primary">
                Submit
              </Button>
              <Button className="ml-1" onClick={() => history.push("/login")} variant="primary">
                Login
              </Button>   
              </Form>
            </Col>
          </Row>
      </Container>
      </>
    )
}

export default Register
